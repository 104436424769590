import React from "react";
import Navbar from "./components/navbar/Navbar";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./components/theme/theme";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Crowsel from "./components/crowsel/Crowsel";
import Typography from "@material-ui/core/Typography";
import PaintingContent from "./components/PaintingContent";
import "./App.css";
import data from "./data";
import { Grid } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleRight,
  faAngleDown
} from "@fortawesome/free-solid-svg-icons";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Navbar />
        <Grid container>
          <img
            src="assets/full_images/cover.png"
            style={{
              alignSelf: "center",
              flexDirection: "row"
            }}
          />
          <div style={{ marginTop: -80, marginBottom: 60 }}>
            <h4>Gallery</h4>
            <FontAwesomeIcon
              icon={faAngleDown}
              style={{ fontSize: 30, marginTop: -50 }}
            />
          </div>
        </Grid>

        <PaintingContent data={data.paintings} />
        {/* <PaintingContent title="Acrylic on canvas" data={data.acrylic} />
        <PaintingContent title="Digital" data={data.digital} /> */}
        <PaintingContent title="Pen and ink on paper" data={data.drawings} />
        <Grid container spacing={1}>
          <img src="assets/full_images/about.jpg" style={{ width: "100%" }} />
        </Grid>
      </div>
    </ThemeProvider>
  );
}

export default App;
