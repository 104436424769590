import React from "react";
import { Typography } from "@material-ui/core";
import Crowsel from "./crowsel/Crowsel";

function PaintingContent(props: any) {
  return (
    <div style={{ backgroundColor: "white" }}>
      <Typography
        variant="h4"
        component="h4"
        style={{
          textAlign: "center",
          marginBottom: 30,
          marginTop: 20,
          color: "#fcf3d3",
        }}
      ></Typography>
      <Crowsel data={props.data} />
    </div>
  );
}

export default PaintingContent;
