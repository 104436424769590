import React, { CSSProperties } from "react";
import { Carousel } from "react-responsive-carousel";
import "./Crowsel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleRight,
  faArrowCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "@material-ui/core";

export default function Crowsel(props: any) {
  console.log(props.data);
  const arrowStyles: CSSProperties = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    backgroundColor: "white",
    border: "none",
    color: "black",
    fontSize: 30,
  };

  return (
    <Carousel
      autoPlay={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <Button
            type="button"
            onClick={onClickHandler}
            style={{ ...arrowStyles, left: 15 }}
          >
            <FontAwesomeIcon icon={faArrowCircleLeft} />
          </Button>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <Button
            type="button"
            onClick={onClickHandler}
            style={{
              ...arrowStyles,
              right: 15,
            }}
          >
            <FontAwesomeIcon icon={faArrowCircleRight} />
          </Button>
        )
      }
    >
      {/* <div>
        <img alt="" src="http://lorempixel.com/output/cats-q-c-640-480-2.jpg" />
        <p>Test</p>
      </div> */}
      {props.data.map((images: any) => {
        return (
          <div>
            <img alt="" src={images.src} />
            <p>
              <span style={{ fontWeight: "bold" }}>{images.name}</span> -
              {images.title}-{images.size} inch
            </p>
          </div>
        );
      })}
      {/* <div>
        <img alt="" src="http://lorempixel.com/output/cats-q-c-640-480-3.jpg" />
        <p className="legend">Legend 3</p>
      </div>
      <div>
        <img alt="" src="http://lorempixel.com/output/cats-q-c-640-480-4.jpg" />
        <p className="legend">Legend 4</p>
      </div> */}
    </Carousel>
  );
}
