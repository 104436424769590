import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "white",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    height: 20,
    alignItems: "flex-start",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
    color: "white",
  },
  text: {
    color: "white",
    marginTop: -10,
  },
}));

function Navbar() {
  const classes = useStyles();

  return (
    <div>
      <AppBar position="static" style={{ height: 60 }}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <img src="assets/full_images/logo.png" style={{}} />
          </IconButton>
          <Typography
            variant="h4"
            component="h4"
            className={classes.title}
          ></Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Navbar;
