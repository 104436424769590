import react from "react";

let data = {
  paintings: [
    {
      name: "Basic Element",
      title: "water color on paper",
      size: "20 x 28",
      src: "assets/full_images/IMAGE1.JPG",
    },
    {
      name: "Dawn",
      title: "water color on paper",
      size: "20 x 28",
      src: "assets/full_images/IMAGE2.JPG",
    },
    {
      name: "Ego",
      title: "water color on paper",
      size: "20 x 28",
      src: "assets/full_images/IMAGE3.JPG",
    },
    {
      name: "Evolution of Nature",
      title: "water color on paper",
      size: "20 x 27",
      src: "assets/full_images/IMAGE4.JPG",
    },
    {
      name: "Festival",
      title: "water color on paper",
      size: "20 x 28",
      src: "assets/full_images/IMAGE5.JPG",
    },
    {
      name: "Folk Tale",
      title: "water color on paper",
      size: "20 x 28",
      src: "assets/full_images/IMAGE6.JPG",
    },
    {
      name: "Lady in rest",
      title: "water color on paper",
      size: "20 x 27",
      src: "assets/full_images/IMAGE7.JPG",
    },
    {
      name: "Lovers Fantasy",
      title: "water color on paper",
      size: "20 x 27",
      src: "assets/full_images/IMAGE8.JPG",
    },
    {
      name: "Spring",
      title: "water color on paper",
      size: "20 x 27",
      src: "assets/full_images/IMAGE9.JPG",
    },
    {
      name: "Seed",
      title: "water color on paper",
      size: "21 x 28",
      src: "assets/full_images/IMAGE10.JPG",
    },
    {
      name: "Landscape",
      title: "water color on paper",
      size: "21 x 28",
      src: "assets/full_images/IMAGE11.JPG",
    },
    {
      name: "Society",
      title: "water color on paper",
      size: "21 x 28",
      src: "assets/full_images/IMAGE12.JPG",
    },
    {
      name: "Parent",
      title: "water color on paper",
      size: "20 x 28",
      src: "assets/full_images/IMAGE13.JPG",
    },
    {
      name: "Romantic Night",
      title: "water color on paper",
      size: "20 x 28",
      src: "assets/full_images/IMAGE14.JPG",
    },
    {
      name: "Sujata",
      title: "water color on paper",
      size: "21 x 28",
      src: "assets/full_images/IMAGE15.JPG",
    },
    {
      name: "Third Eye",
      title: "water color on paper",
      size: "20 x 27",
      src: "assets/full_images/IMAGE16.JPG",
    },
    {
      name: "Summer",
      title: "water color on paper",
      size: "21 x 28",
      src: "assets/full_images/IMAGE17.JPG",
    },
    {
      name: "Who am I",
      title: "water color on paper",
      size: "20 x 28",
      src: "assets/full_images/IMAGE18.JPG",
    },
    {
      name: "Incarnation",
      title: "water color on paper",
      size: "20 x 28",
      src: "assets/full_images/IMAGE19.JPG",
    },
    {
      name: "Prosperity",
      title: "water color on paper",
      size: "21 x 28",
      src: "assets/full_images/IMAGE20.JPG",
    },
    {
      name: "Lotus Lady",
      title: "water color on paper",
      size: "21 x 28",
      src: "assets/full_images/IMAGE21.JPG",
    },
    {
      name: "Rising Star",
      title: "water color on paper",
      size: "20 x 28",
      src: "assets/full_images/IMAGE22.JPG",
    },
    {
      name: "Indian Lady",
      title: "water color on paper",
      size: "20 x 28",
      src: "assets/full_images/IMAGE23.JPG",
    },
    {
      name: "Spirit",
      title: "water color on paper",
      size: "20 x 27",
      src: "assets/full_images/IMAGE24.JPG",
    },

    {
      name: "Prist",
      title: "acrylic on canvas",
      size: "48 x 72",
      src: "assets/full_images/IMAGE25.JPG",
    },
    {
      name: "Laxmi",
      title: "acrylic on canvas",
      size: "48 x 72",
      src: "assets/full_images/IMAGE26.JPG",
    },
    {
      name: "Lady in the Garden",
      title: "acrylic on canvas",
      size: "36 x 36",
      src: "assets/full_images/IMAGE27.JPG",
    },

    {
      name: "Bhairvi",
      title: "Digital",
      size: "36 x 36",
      src: "assets/full_images/IMAGE28.JPG",
    },
    {
      name: "Dhyan",
      title: "Digital",
      size: "37 x 40",
      src: "assets/full_images/IMAGE29.JPG",
    },
    {
      name: "Maternity",
      title: "Digital",
      size: "37 x 40",
      src: "assets/full_images/IMAGE30.JPG",
    },
    {
      name: "Aditi",
      title: "Digital",
      size: "35 x 42",
      src: "assets/full_images/IMAGE31.JPG",
    },
    {
      name: "Tara",
      title: "Digital",
      size: "22 x 28",
      src: "assets/full_images/IMAGE32.JPG",
    },
    {
      name: "Gravity",
      title: "Digital",
      size: "22 x 22",
      src: "assets/full_images/IMAGE33.JPG",
    },
    {
      name: "Kam",
      title: "Digital",
      size: "22 x 28",
      src: "assets/full_images/IMAGE34.JPG",
    },
    {
      name: "Red Point",
      title: "Digital",
      size: "22 x 25",
      src: "assets/full_images/IMAGE35.JPG",
    },
  ],
  drawings: [
    {
      name: "Divine Dance",
      title: "pen n ink on paper",
      size: "9 x 11",
      src: "assets/full_images/IMAGE36.JPG",
    },
    {
      name: "Incredable Nature",
      title: "pen n ink on paper",
      size: "9 x 11",
      src: "assets/full_images/IMAGE37.JPG",
    },
    {
      name: "Maternal Lust",
      title: "pen n ink on paper",
      size: "9 x 11",
      src: "assets/full_images/IMAGE38.JPG",
    },
    {
      name: "Melodic Movement,",
      title: "pen n ink on paper",
      size: "9 x 11",
      src: "assets/full_images/IMAGE39.JPG",
    },
    {
      name: "Lady at work",
      title: "pen n ink on paper",
      size: "9 x 11",
      src: "assets/full_images/IMAGE40.JPG",
    },
    {
      name: "In the Garden",
      title: "pen n ink on paper",
      size: "9 x 11",
      src: "assets/full_images/IMAGE41.JPG",
    },
    {
      name: "Night Angle",
      title: "pen n ink on paper",
      size: "9 x 11",
      src: "assets/full_images/IMAGE42.JPG",
    },
    {
      name: "Mistry",
      title: "pen n ink on paper",
      size: "9 x 11",
      src: "assets/full_images/IMAGE43.JPG",
    },
    {
      name: "On the Flight",
      title: "pen n ink on paper",
      size: "9 x 11",
      src: "assets/full_images/IMAGE44.JPG",
    },
    {
      name: "After Disaster",
      title: "pen n ink on paper",
      size: "9 x 11",
      src: "assets/full_images/profile1.png",
    },
    {
      name: "Hostile",
      title: "pen n ink on paper",
      size: "9 x 15",
      src: "assets/full_images/profile2.png",
    },
    {
      name: "Family",
      title: "pen n ink on paper",
      size: "9 x 15",
      src: "assets/full_images/profile3.png",
    },
    {
      name: "Around the tomb",
      title: "pen n ink on paper",
      size: "9 x 15",
      src: "assets/full_images/profile4.png",
    },
    {
      name: "Excuse",
      title: "pen n ink on paper",
      size: "9 x 15",
      src: "assets/full_images/profile5.png",
    },
  ],
};

export default data;
